<template>
	<div>
		<div
			class="min-w-screen bg-gray-800 bg-opacity-75 flex flex-col gap-9 px-5 py-10"
		>
			<h1 class="text-4xl font-bold text-center">Login</h1>
			<div class="flex justify-center">
				<div
					class="w-full max-w-xs"
					v-on:keyup.enter="login"
					action="/login"
				>
					<div
						v-if="error"
						class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
						role="alert"
					>
						<span class="block sm:inline">{{ error }}</span>
						<span class="absolute top-0 bottom-0 right-0 px-4 py-3">
							<svg
								@click="error = null"
								class="fill-current h-6 w-6 text-red-500"
								role="button"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 20 20"
							>
								<title>Close</title>
								<path
									d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"
								/>
							</svg>
						</span>
					</div>
					<input
						v-model="username"
						class="mb-4 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
						type="text"
						placeholder="Username"
						autofocus
					/>
					<input
						v-model="password"
						class="mb-6 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
						type="password"
						placeholder="Password"
					/>
					<div class="flex justify-center">
						<button
							@click="login"
							class="bg-red-800 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
							type="button"
						>
							Login
						</button>
					</div>
					<p class="text-center text-gray-100 text-xs mt-9">
						&copy;{{ new Date().getFullYear() }} ADK Hospital. All
						rights reserved.
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				username: "",
				password: "",
				error: null,
			};
		},
		mounted() {
			if (this.$store.getters.isLoggedIn) {
				this.$router.push("/");
			}
		},
		methods: {
			login: function () {
				let username = this.username;
				let password = this.password;
				this.$store
					.dispatch("login", { username, password })
					.then(() => this.$router.push("/"))
					.catch(err => {
						if (err.response.data) {
							this.error = err.response.data.msg;
						}
					});
			},
		},
	};
</script>
